import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import {initializeFirestore}      from '@firebase/firestore';
import {yakofficeFirebaseConfig}  from "./yakoffice-firebase-config";

export function initYakofficeFirebaseApp(useEmulators?: boolean) : firebase.app.App {

  const REACT_APP_FIREBASE_CONFIG =  process.env.REACT_APP_FIREBASE_CONFIG || "development";
  const REACT_APP_USE_FIREBASE_EMULATORS = useEmulators || !!process.env.REACT_APP_USE_FIREBASE_EMULATORS

  console.log("REACT_APP_NODE_ENV: ", process.env.NODE_ENV )
  console.log("REACT_APP_FIREBASE_CONFIG: ", REACT_APP_FIREBASE_CONFIG )
  console.log("REACT_APP_USE_FIREBASE_EMULATORS: ", REACT_APP_USE_FIREBASE_EMULATORS)

  const options = REACT_APP_FIREBASE_CONFIG === "production"
    ? yakofficeFirebaseConfig.prd
    : yakofficeFirebaseConfig.dev;

  // Emulate RTDB
  if(REACT_APP_USE_FIREBASE_EMULATORS) {
    options.databaseURL = `http://localhost:9000?ns=${options.projectId}`;
    console.log(`Using RTDB emulator: ${options.databaseURL}`);
  }

  const defaultFirebaseApp = firebase.initializeApp(options);
  console.log("Default Firebase App: " + defaultFirebaseApp.name);

  // Emulate Firestore
  if (REACT_APP_USE_FIREBASE_EMULATORS) {
    const firestoreSettings = {
      host:"localhost:8080",
      ssl: false,
      // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
      experimentalForceLongPolling: true
    };

    console.log(`Using Firestore emulator: ${firestoreSettings.host}`);
    initializeFirestore(defaultFirebaseApp, firestoreSettings);
    // firebase.firestore().settings(firestoreSettings);
  }

  return defaultFirebaseApp;
}
