import { YoConfig, YoProject, YoProjectToolGeMap, YoRole, YoTool, YoUser } from '../types';

export interface YoBuildResult<T>{
  id: string,
  json: any,
  instance: T,
  firestorePath: string
}

export interface YoToolOverrides {
  id?: string,
  name?: string,
  projectUrl?:string,
  globalRoles?:  YoRole[],
  projectRoles?:  YoRole[],
  gameEnvironmentRoles?:  YoRole[]
}

export interface YoBuildGameEnvironment{
  id: string,
  name: string,
}

export class YoBuild {

  static Config = () => {
    return YoBuild.yoConfig({});
  }

  static yoConfig = (configPropertyOverrides: any) : YoBuildResult<YoConfig> => {
    const yoConfig :YoConfig = {
      portalUrl: "http://localhost:3000",
      portalProjectsRoute: "/projects",
      portalToolsRoute: "/projects/:projectId/tools",
      portalUsersRoute: "/users",
      portalWeblogoRoute: "",
      portalMobilelogoRoute: "",
      ...configPropertyOverrides
    };
    return {
      id: "auth",
      json: yoConfig,
      instance: yoConfig,
      firestorePath: `/yakoffice/auth`
    };
  }

  static GlobalRole1 = () => {return YoBuild.yoRole({id: "globalRole1",name: "Global Role 1",description: "Global Role 1 Description"}); }
  static GlobalRole2 = () => {return YoBuild.yoRole({id: "globalRole2",name: "Global Role 2",description: "Global Role 2 Description"}); }

  static ProjectRole1 = () => {return YoBuild.yoRole({id: "projectRole1",name: "Project Role 1",description: "Project Role 1 Description"}); }
  static ProjectRole2 = () => {return YoBuild.yoRole({id: "projectRole2",name: "Project Role 2",description: "Project Role 2 Description"}); }

  static GeRole1 = () => {return YoBuild.yoRole({id: "geRole1",name: "GameEnvironment Role 1",description: "Game Environment Role 1 Description"}); }
  static GeRole2 = () => {return YoBuild.yoRole({id: "geRole2",name: "GameEnvironment Role 2",description: "Game Environment Role 2 Description"}); }

  static yoRole = (rolePropertyOverrides: any) : YoBuildResult<YoRole> => {
    const yoRole : YoRole = {
      id: "",
      name: "",
      description: "",
      ...rolePropertyOverrides
    }

    return {id: yoRole.id, json: yoRole, instance: yoRole, firestorePath: '' };
  }

  static Tool1 = (toolPropertyOverrides?: YoToolOverrides) => {
    return YoBuild.yoTool({
      id: "tool1",
      name: "Tool 1",
      projectUrl: "http://tool1.com/:projectId",
      ...toolPropertyOverrides
    });
  }

  static Tool2 = (toolPropertyOverrides?: YoToolOverrides ) => {
    return YoBuild.yoTool({
      id: "tool2",
      name: "Tool 2",
      projectUrl: "http://tool2.com/:projectId",
      ...toolPropertyOverrides
    });
  }
  static Tool3 = (toolPropertyOverrides?: YoToolOverrides) => {
    return YoBuild.yoTool({
      id: "tool3",
      name: "Tool 3",
      projectUrl: "http://tool3.com/:projectId",
      ...toolPropertyOverrides
    });
  }

  static yoTool = (toolPropertyOverrides: YoToolOverrides) : YoBuildResult<YoTool> => {
    const yoTool : YoTool = {
      id: "",
      name: "",
      projectUrl: "",
      fontawesomeClassname: "",
      globalRoles: null,
      projectRoles: null,
      gameEnvironmentRoles: null,
      ...toolPropertyOverrides
    }

    const {id, ...json} = yoTool;
    return {id, json, instance: yoTool, firestorePath: `/yakoffice/auth/tools/${id}` };
  }

  static Project1 = (tools: YoTool[] = [], gameEnvironments: YoProjectToolGeMap[] = []) => {
    return YoBuild.yoProject({
      id: "project1",
      name: "Project 1",
      tools: tools.map((tool) => (
        {
          toolId: tool.id,
          toolProjectId: "project1",
          gameEnvironments: gameEnvironments
        }))
    });
  }

  static Project2 = (tools: YoTool[] = [], gameEnvironments: YoProjectToolGeMap[] = []) => {
    return YoBuild.yoProject({
      id: "project2",
      name: "Project 2",
      tools: tools.map(tool => (
        {
          toolId: tool.id,
          toolProjectId: "project2",
          gameEnvironments: gameEnvironments
        }))
    });
  }

  static yoProject = (projectPropertyOverrides: any) : YoBuildResult<YoProject> => {
    const yoProject : YoProject = {
      id: "",
      name : "",
      imageUrl: "",
      tools: null,
      ...projectPropertyOverrides
    }

    const {id, ...json} = yoProject;
    return {id, json, instance: yoProject, firestorePath: `/yakoffice/auth/projects/${id}`};
  }

  static yoUser = (userPropertyOverrides: any, projects : {project: YoProject, tools: YoTool[]}[]) : YoBuildResult<YoUser> => {
    const yoUser : YoUser = {
      "email": "",
      "isAdmin": false,
      "projects": projects.map(projectToolMap => ({
        "projectId": projectToolMap.project.id,
        "tools": projectToolMap.tools.map(tool => ({
            "toolProjectId": projectToolMap.project.id,
            "gameEnvironments": [],
            "toolId": tool.id
          }
        ))
      })),
      ...userPropertyOverrides
    }

    const {email, ...json} = yoUser;
    return {id: email, json, instance: yoUser, firestorePath: `/yakoffice/auth/users/${email}`};
  }
}
