import {flow, Instance, types} from "mobx-state-tree";
import {Tool} from "../model/tool";
import { ToolFirebaseApi, YoRole, YoTool } from '@yakoffice/yakoffice-firebase';

const toolFirebaseApi = ToolFirebaseApi();

export const ToolStore = types.model(
    "ToolStore",
    {
        tools: types.array(Tool),
    })
    .actions(self => ({

        loadTools : flow(function*() {
            try {

              const firebaseTools = yield toolFirebaseApi.getToolsConfig();

                const tools = firebaseTools.map((firebaseTool: YoTool) => Tool.create(MapToToolModel(firebaseTool)))
                self.tools.replace(tools);
            } catch (e: any) {
              throw new Error(`Failed to load tools: ${e.message}`);
            }})
    }));

const MapToToolModel = (tool: YoTool) => {
    return {
        id: tool.id,
        name: tool.name,
        projectUrl: tool.projectUrl,
        fontawesomeClassname: tool.fontawesomeClassname,
        globalRoles: MapRoles(tool.globalRoles),
        projectRoles: MapRoles(tool.projectRoles),
        gameEnvironmentRoles: MapRoles(tool.gameEnvironmentRoles),
    }
};

const MapRoles = (roles: YoRole[] | null) => {
  return roles?.map(role => {
    return {
      id: role.id,
      name: role.name,
      description: role.description
    }
  }) || []
}

export interface IToolStore extends Instance<typeof ToolStore> {}
