import React, { FC } from 'react';
import {Link}       from 'react-router-dom';
import Row          from 'react-bootstrap/Row';
import Col          from 'react-bootstrap/Col';
import Form         from 'react-bootstrap/Form'
import Button       from 'react-bootstrap/Button';
import type {IUser}      from '../../model/user';
import type {IProject}   from '../../model/project';
import type {ITool}      from '../../model/tool';
import {PortalRouteItems}     from '../../routes/PortalRouteItems';
import {ViewHeader} from '@yakoffice/view-header';
import {observer} from 'mobx-react-lite';
import { isStateTreeNode, onSnapshot } from 'mobx-state-tree';
import { UserController } from '../../controllers/userController';
import { UserProjectTabs } from '../user-project-tabs/user-project-tabs';
import { GlobalRoles } from '../global-roles/global-roles';
import {LabelHeader, usePrompt} from '@yakoffice/shared-components';

interface PropsType {
    projects                : IProject[];
    tools                   : ITool[];
    user                    : IUser;
}

export const UserForm: FC<PropsType> = observer((props: PropsType) => {

  const [pageEdited, setPageEdited] = usePrompt("You have unsaved changes. Are you sure you want to leave?", false);
  // Flush sync to prevent batching which prevents immediate rerender
  const controller = UserController({ setUserEditStatus: setPageEdited });

  if (isStateTreeNode(props.user))// So can pass in mock....
    onSnapshot(props.user, () => setPageEdited(true));

  const userEmailChange = (e: any) => {
    props.user.updateUserEmail(e.target.value);
  }

  const userAdminChange = (e: any) => {
    props.user.updateUserAdmin(e.target.value === "true");
  }

  return (
    <div className="container mt-4">
      <ViewHeader
        title={`${props.user.email ? props.user.email : "(New User)"}`}
        customAction={!props.user.isNew &&
        <Button variant="danger ms-1" onClick={controller.deleteUser}>Delete User</Button>}
      />
      <Row>
        <Col md="6">
          <Form.Group>
            <Form.Label htmlFor="userEmail" className=""><LabelHeader><i className="fas fa-at" /> Email</LabelHeader></Form.Label>
            <Form.Control type="email" id="userEmail" placeholder={props.user.email} defaultValue={props.user.email} onChange={userEmailChange} />
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group>
            <Form.Label><LabelHeader><i className="fas fa-globe-europe" /> User Administrator</LabelHeader></Form.Label>
            <Form.Select defaultValue={props.user.isAdmin.toString()} onChange={userAdminChange}>
              <option hidden>Select...</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
        <Col>
          <GlobalRoles tools={props.tools} user={props.user}/>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <UserProjectTabs projects={props.projects} tools={props.tools} user={props.user}/>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="12" className="text-end">
          <Link to={"/"+PortalRouteItems.Users} className="btn btn-secondary" data-testid="btnCancel">Cancel</Link>
          <Button variant="success ms-1" onClick={controller.saveUser} disabled={!pageEdited} data-testid="btnSave">Save User</Button>
        </Col>
      </Row>
    </div>
  )
})

export default UserForm;
