import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IProject } from '../../model/project';
import { ITool } from '../../model/tool';
import { IUser } from '../../model/user';
import { ToolLabel } from '../tool-label/tool-label';

interface PropTypes{
  project : IProject,
  tool: ITool,
  user: IUser
}

export const ToolLabelAndEnable : FC<PropTypes> = observer(({project,tool, user}) => {

  const handleProjectToolStatusChange = (project: IProject, tool: ITool, enableTool: boolean) => {
    user.updateProjectToolStatus(project, tool, enableTool);
  }

  const toolEnabledForProject = user.isToolEnabledForProject(project.id, tool.id);

  return (
    <Form.Group className="mb-2">
      <Row>
        <Col md="2">
          <ToolLabel tool={tool}/>
        </Col>
        <Col>
          <Form.Check type="switch"
                      id={`${project.id}-${tool.id}-switch`}
                      data-testid={`tool-auth-switch-${tool.id}`}
                      defaultChecked={toolEnabledForProject}
                      onChange={() => handleProjectToolStatusChange(project, tool, !toolEnabledForProject)}
                      label="Authorise"
          />
        </Col>
      </Row>
    </Form.Group>)
})
