import React, { CSSProperties, FC } from 'react';

import styles from './project-logo.module.sass';

type PropTypes =  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> &
{
 height?: number
}

export const ProjectLogo : FC<PropTypes> = ({ className, height, ...props}) => {

  const style : CSSProperties = {};
  if(height)
    style.height = `${height}px`

  return <img style={style} className={`${styles.projectLogo} ${className ?? ""}`} {...props} />
}

