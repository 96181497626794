import {Instance, types} from "mobx-state-tree";
import { ProjectToolGameEnvironment } from './project-tool-game-environment';

export const ProjectToolMap = types.model(
    "ProjectToolMap",
    {
        toolId:"",
        projectId: "",
        gameEnvironments: types.array(ProjectToolGameEnvironment)
    });

export interface IProjectToolMap extends Instance<typeof ProjectToolMap> {}
