import * as React from "react";
import {CustomSpinner} from "@yakoffice/custom-spinner";
import {FC, PropsWithChildren} from 'react';
import {AuthUserContext, useYakofficeAuthorisedUser } from '@yakoffice/yakoffice-firebase';
import { FirebaseApp }                  from '@firebase/app';
import {getAuth} from "firebase/auth";
import {Button} from "react-bootstrap";
import {CenteredPanel} from "@yakoffice/shared-components";


interface PropTypes{
  toolId: string
  firebaseApp?: FirebaseApp
}
export const WithAuthorisation : FC<PropsWithChildren<PropTypes>> = ({toolId, firebaseApp, children}) => {

  const authorisationResult = useYakofficeAuthorisedUser(toolId, firebaseApp);

  if(authorisationResult.error)
    return <CenteredPanel>
      <p>{authorisationResult.error.message}</p>
      <Button onClick={() => getAuth().signOut()}>Sign out</Button>
    </CenteredPanel>;

  if (!authorisationResult.hasAuthorised)
    return <CustomSpinner spinnerText="Authorising..."/>;

  return <AuthUserContext.Provider value={authorisationResult.yakofficeUser}>
    {children}
  </AuthUserContext.Provider>
}
