import React, { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { observer } from 'mobx-react-lite';
import { ITool } from '../../model/tool';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IProject } from '../../model/project';
import { IUser } from '../../model/user';
import { IRole } from '../../model/role';
import { RoleDescriptionPopover } from '../role-description-popover/role-description-popover';
import { LabelHeader } from '@yakoffice/shared-components';

interface PropTypes{
  project: IProject,
  tool: ITool,
  user: IUser
}

export const ProjectRoles : FC<PropTypes> = observer(({project, tool, user}) => {

  const handleToggleRoleStatus = (tool: ITool, role: IRole, enableRole: boolean ) => {
    user.setProjectRoleStatus(project.id, tool.id, role.id, enableRole)
  }

  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            <LabelHeader><i className={`fa fa-lock`} /> Project Roles <RoleDescriptionPopover roles={tool.projectRoles}/></LabelHeader>
          </Form.Label>
        </Col>
      </Row>
      <Row data-testid={`tool-project-roles-${tool.id}`}>
        <Col>
        {
          tool.projectRoles.map(projectRole => {
            const userHasRole = user.hasProjectRole(project.id, tool.id, projectRole.id);
            return <Form.Check
              key={`${tool.id}-${projectRole.id}`}
              type="checkbox"
              id={projectRole.id}
              data-testid={`project-auth-checkbox-${tool.id}-${projectRole.id}`}
              defaultChecked={userHasRole}
              onChange={() => handleToggleRoleStatus(tool, projectRole, !userHasRole)}
              label={projectRole.name} />
          })
        }
        </Col>
      </Row>
    </>

  );
})
