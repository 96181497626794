import 'firebase/auth';
import {User} from "firebase/auth";
import { YoUser } from './';

export class YoAuthUser {
  readonly firebaseUser: User;
  readonly yoUser: YoUser
  readonly hostedToolId: string

  constructor(firebaseUser: User, yoUser: YoUser, hostedToolId: string) {
    this.firebaseUser = firebaseUser;
    this.yoUser = yoUser;
    this.hostedToolId = hostedToolId;
  }

  // METHODS REQUIRED BY SHARED HEADER (i.e. they use the Portal Ids)

  // projectId: Must be the projectId stored in Yakoffice Firestore config and not the projectId stored in individual apps.  Mappings are available from Yakoffice Firestore
  isAuthorisedForProject(projectId: string) {
    return this.findProject(projectId) != null;
  }

  // projectId: Must be the projectId stored in Yakoffice Firestore config and not the projectId stored in individual apps.  Mappings are available from Yakoffice Firestore
  // toolId:  Must be the toolId stored in Yakoffice Firestore.  Mappings are available from Yakoffice Firestore
  isAuthorisedForTool(projectId: string, toolId: string) {
    return this.findTool(projectId, toolId) != null;
  }

  private findTool(projectId: string, toolId: string){
    return this.findProject(projectId)?.tools?.find(userProjectTool => userProjectTool.toolId === toolId);
  }

  private findProject(projectId: string) {
    return this.yoUser.projects.find(userProject => userProject.projectId === projectId);
  }

  // METHODS REQUIRED BY THE TOOLS TO ENFORCE AUTH (i.e. they user the Tool specific Ids)

  isAuthorisedForHostedToolProject(toolProjectId: string) {
    return this.findToolProject(toolProjectId) != null;
  }

  hasGameEnvironmentRoleClaim(toolProjectId: string, toolGeId: string, roleId: string) {
    return this.findToolProjectToolGe(toolProjectId, toolGeId)?.roles.find(r => r === roleId) != null;
  }

  getFirstAuthorisedGameEnvironmentId(toolProjectId: string){
    return this.findToolProjectTool(toolProjectId)?.gameEnvironments
      ?.slice()
      .sort((ge1, ge2) => ge1.toolGeId.localeCompare(ge2.toolGeId))
      .find(() => true)?.toolGeId;
  }

  private findToolProjectToolGe(toolProjectId: string, toolGeId: string){
    return this.findToolProjectTool(toolProjectId)?.gameEnvironments?.find(ge => ge.toolGeId === toolGeId);
  }

  private findToolProjectTool(toolProjectId: string){
    return this.findToolProject(toolProjectId)?.tools.find(t => t.toolId === this.hostedToolId)
  }

  private findToolProject(toolProjectId: string){
    return this.yoUser.projects?.find(p => p.tools.find(t => t.toolId === this.hostedToolId && t.toolProjectId === toolProjectId));
  }

}

