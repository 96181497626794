import { Instance, types } from 'mobx-state-tree';
import { UserToolGameEnvironment } from './user-tool-game-environment';

export const UserProjectTool = types.model(
  "UserProjectTool",
  {
    toolId: types.string,
    toolProjectId: types.string,
    projectRoles: types.array(types.string),
    gameEnvironments: types.array(UserToolGameEnvironment)
  }
)

export interface IUserProjectTool extends Instance<typeof UserProjectTool> {}
