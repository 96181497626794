import React, {FC}              from 'react';
import {observer}               from 'mobx-react-lite';
import {Link}                   from 'react-router-dom';
import { CustomSpinner }        from '@yakoffice/custom-spinner';
import UsersTable               from './usersTable';
import {PortalRouteItems}                 from '../PortalRouteItems';
import {useUsers, useProjects, useTools} from '../../components';
import {ViewHeader}             from '@yakoffice/view-header';
import styles from './index.module.sass'

export const UsersIndex : FC = observer(() => {

    const loadUsersResult       = useUsers();
    const loadProjectsResult    = useProjects();
    const loadToolsResult       = useTools();

    return !loadProjectsResult.isLoaded || !loadToolsResult.isLoaded || !loadUsersResult.isLoaded
           ? <CustomSpinner spinnerText="Loading Users..."/>
           : <div className={`${styles.users} container mt-4`}>
                <ViewHeader
                    title={"Global Users"}
                    customAction={<Link to={PortalRouteItems.Add_User} className="btn btn-primary"><i className="fas fa-plus-circle me-2"/>Add new user</Link>}
                />
               {loadUsersResult.users && loadUsersResult.users.length > 0 && loadProjectsResult.projects && loadToolsResult.tools &&
                    <UsersTable users={loadUsersResult.users} projects={loadProjectsResult.projects} tools={loadToolsResult.tools} />
               }
           </div>

})

export default UsersIndex;

