import 'firebase/auth';
import 'firebase/firestore';
import { YoObserver, YoProject} from '../types';
import { FirebaseAPI } from './firebase-api';
import { YakofficeFirestoreConfigPath } from '../utils';
import { DocMapping } from './mappings/doc-mapping';

const firebaseAPI = FirebaseAPI();

// Project API which describes all the Projects available and the Tools that can be used with them
export const ProjectFirebaseApi = () => {

  const projectsPath = `${YakofficeFirestoreConfigPath}/projects`;

  const mapToProject: DocMapping<YoProject> = (id, data) => {
    return { ...{ id: id}, ...data } as YoProject
  }

  const getProjectsConfig = async (): Promise<YoProject[]> => {
    return firebaseAPI.getCollectionItems(projectsPath, mapToProject)
  }

  // Subscribes an observer to YoProjects snapshot updates from Firestore
  const subscribeToProjectsSnapshots = (observer: YoObserver<YoProject[]>): (() => void) => {
    return firebaseAPI.subscribeToCollectionSnapshots(projectsPath, mapToProject, observer);
  }

  return {
    getProjectsConfig,
    subscribeToProjectsSnapshots
  }

}
