import React, {FC}              from 'react';
import { ITool } from '../../model/tool';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IProject } from '../../model/project';
import { IUser } from '../../model/user';
import { observer } from 'mobx-react-lite';
import Alert from 'react-bootstrap/Alert';
import { ToolLabelAndEnable } from '../tool-label-and-enable/tool-label-and-enable';
import { GameEnvironmentRoles } from '../game_environment-roles/game_environment-roles';
import { ProjectRoles } from '../project-roles/project-roles';

interface PropTypes {
  project : IProject,
  tools: ITool[],
  user: IUser
}

export const UserProjectTabContents : FC<PropTypes> = observer(({project, tools,user}) => {


  return (<div className="mt-3">
      {
        project.tools
          .slice()
          .sort((p1, p2) => p1.toolId.localeCompare(p2.toolId))
          .map(projectTool => {

            const tool = tools.find(t => t.id === projectTool.toolId)
            if (!tool)
              return <Alert>The Project has a tool, with id ({projectTool.toolId}), configured for it which cannot be found.</Alert>

            return (
              <Row key={`${project.id}-${tool.id}`}>
                <Col md="12">
                  <Row>
                    <Col md="12">
                      <ToolLabelAndEnable project={project} tool={tool} user={user} />
                    </Col>
                  </Row>
                  <Row>
                    {user.isToolEnabledForProject(project.id, tool.id) && tool.projectRoles.length > 0 &&
                    <Col md="auto" className="ms-4">
                      <ProjectRoles project={project} tool={tool} user={user} />
                    </Col>
                    }
                    {user.isToolEnabledForProject(project.id, tool.id) && projectTool.gameEnvironments.length > 0 && tool.gameEnvironmentRoles.length > 0 &&
                      <Col md="auto" className="ms-4">
                        <GameEnvironmentRoles project={project} projectTool={projectTool} tool={tool} user={user} />
                      </Col>
                    }
                  </Row>
                  <hr />
                </Col>
              </Row>
            )
          })
      }
    </div>
  )
})
