import {useContext, useEffect, useState} from "react";
import {YoTool} from '@yakoffice/yakoffice-firebase';
import {YoProject} from '@yakoffice/yakoffice-firebase';
import {useYoConfigSnapshots} from '@yakoffice/yakoffice-firebase';
import {useYoProjectsSnapshots} from '@yakoffice/yakoffice-firebase';
import {useYoToolsSnapshots} from '@yakoffice/yakoffice-firebase';
import {useLocation} from "react-router-dom";
import {AuthUserContext, RouteParams} from '@yakoffice/yakoffice-firebase';

export const useYakofficeHeader = (hostedInPortal: boolean, hostedInToolId: string | null) => {

    const authUser = useContext(AuthUserContext);

    const [selectedProject, setSelectedProject] = useState<YoProject | null>(null);
    const [selectedTool, setSelectedTool] = useState<YoTool | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const {yoConfig, error: yoConfigError} = useYoConfigSnapshots();
    const {yoProjects, error: yoProjectsError} = useYoProjectsSnapshots();
    const {yoTools, error: yoToolsError} = useYoToolsSnapshots();
    const location = useLocation();

    useEffect(() => {
            if (yoProjects.length > 0 && yoTools.length > 0 && yoConfig != null) {
                let project: YoProject | null = null;

                // ViewHeader hosted in portal and route is longer than /projects so assume a project has been selected
                if(hostedInPortal){ // https://domain/projects/:projectId/tools = projects/:projectId/tools  = 3 splits
                    const splitHref = new URL(window.location.href).pathname.split("/");
                    const projectIdIndexInSplitRoute = yoConfig.portalToolsRoute.split("/").indexOf(RouteParams.ProjectId);
                    if(splitHref.length > projectIdIndexInSplitRoute) {
                        const projectId = splitHref[projectIdIndexInSplitRoute];
                        project = yoProjects.find(p => p.id === projectId) || null;
                    }
                }
                else{
                    // Hosted in an app so have to find the config project_id from the app project_id
                    if(selectedTool != null) {
                        const toolProjectId = window.location.href.split("/")[selectedTool.projectUrl.split("/").indexOf(RouteParams.ProjectId)]
                        project = yoProjects.find(p => p.tools.find(t => t.toolId === selectedTool.id && t.toolProjectId === toolProjectId) != null) || null;
                    }
                }

                if(project != null)
                    setSelectedProject(project)
            }
        },[hostedInPortal, hostedInToolId, yoProjects, yoConfig, yoTools, location, selectedTool]
    );

    useEffect(() => {
            if (yoTools.length > 0) {
                const tool = hostedInToolId != null
                    ? yoTools.find(tool => tool.id === hostedInToolId) || null
                    : yoTools.find(tool => getHostname(tool.projectUrl) === window.location.hostname) || null;

                if (tool != null)
                    setSelectedTool(tool)
            }
        },[yoTools, hostedInToolId, location]
    );

    useEffect(() => {
            if(yoConfigError != null)
                setError(yoConfigError)

            if(yoProjectsError != null)
                setError(yoProjectsError)

            if(yoToolsError != null)
                setError(yoToolsError)

        },[yoConfigError, yoProjectsError, yoToolsError]
    )

    const isDevSite = () => {
      return hostedOnYakOfficeAppUrl(window.location.hostname);
    }

    return {authUser, yoConfig, yoProjects, yoTools, selectedProject, selectedTool, error, isDevSite}
}

const getHostname = (url: string) : string | null =>
{
    const splits = url.split("/");

    if(splits.length > 2)
        return splits[2].toLowerCase()

    return null;
}

const hostedOnYakOfficeAppUrl = (url: string) => {

  return !url.match(/yakoffice.app/g);

}
