import * as React    from 'react';
import Spinner  from 'react-bootstrap/Spinner';
import styles from './custom-spinner.module.sass'

export interface ISpinnerProps {
  spinnerText : string,
  className? : string
  position?: "absolute" | "relative"
}

export const CustomSpinner = (props: ISpinnerProps) => {
  return  (
      <div className={`${props.position === "relative" ? styles.relativeSpinner : styles.absoluteSpinner} text-center mt-5 ${props.className ?? ""}`}>
        <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>
        <div className="mt-3 spinner-text">
          <strong>{props.spinnerText}</strong>
        </div>
      </div>
  )
};
