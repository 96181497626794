import {useEffect, useState}  from "react";
import { getAuth, User } from "firebase/auth";
import { FirebaseApp } from '@firebase/app';

export interface YakofficeAuthenticatedUserResult {
  hasAuthenticated: boolean;
  firebaseUser    : User | null;
}

export const useYakofficeAuthenticatedUser = (app?: FirebaseApp) : YakofficeAuthenticatedUserResult => {

  const [result , setResult]  = useState<YakofficeAuthenticatedUserResult>(() =>({
        hasAuthenticated  : false,
        firebaseUser      : null }));

  const auth = getAuth(app)

  useEffect(() => {
        if (auth.currentUser) {
          setResult({hasAuthenticated: true, firebaseUser: auth.currentUser})
        } else {
          setResult({hasAuthenticated: false, firebaseUser: null})
        }
  }, [auth.currentUser]);

  return result;
}
