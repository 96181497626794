
import * as React from "react";
import {FC, PropsWithChildren, useState} from "react";
import {NotificationPublisherContext} from './notification-publisher-context'
import {Notifications} from "./notifications";
import {INotificationData} from "./types";

export const NotificationHandler : FC<PropsWithChildren> = ({children}) => {

  const [notifications, setNotifications] = useState<INotificationData[]>([]);

  const [publishNotification] = useState(() =>
    (newNotification: INotificationData) => setNotifications(currentNotifications => [...currentNotifications, newNotification])
  );

  const [deleteNotification] = useState(() =>
    (notificationToDelete: INotificationData) => setNotifications(currentNotifications => currentNotifications.filter(n => n !== notificationToDelete))
  );

  const clearNotifications = () => {
    setNotifications([])
  }

  return (
    <NotificationPublisherContext.Provider value={publishNotification}>
      {children}
      <Notifications notifications={notifications} handleDeleteNotification={deleteNotification}
                     handleClearNotifications={clearNotifications} />
    </NotificationPublisherContext.Provider>
  )
}
