import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { RoleDescriptionPopover } from '../role-description-popover/role-description-popover';
import Row from 'react-bootstrap/Row';
import { ITool } from '../../model/tool';
import { ToolLabel } from '../tool-label/tool-label';
import { IUser } from '../../model/user';
import { IRole } from '../../model/role';
import { LabelHeader } from '@yakoffice/shared-components';


interface PropTypes{
  tools: ITool[],
  user: IUser
}

export const GlobalRoles : FC<PropTypes> = observer(({tools, user}) =>{

  const handleToggleRoleStatus = (tool: ITool, role: IRole, enableRole: boolean ) => {
    user.setGlobalRoleStatus(tool.id, role.id, enableRole)
  }

  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            <LabelHeader><i className={`fa fa-globe`} /> Global Roles</LabelHeader>
          </Form.Label>
        </Col>
      </Row>
      {tools
        .filter(tool => tool.globalRoles.length > 0)
        .map(tool => (
          <Row key={tool.id} data-testid={`tool-global-roles-${tool.id}`} className="ms-4">
            <Col md="2">
              <ToolLabel tool={tool}/> <RoleDescriptionPopover roles={tool.globalRoles}/>
            </Col>
            <Col>
              <Row>
              {tool.globalRoles.map(globalRole => {
                const userHasRole = user.hasGlobalRole(tool.id, globalRole.id);
                return <Col md="3" key={`${tool.id}-${globalRole.id}`}><Form.Check
                  type="checkbox"
                  id={globalRole.id}
                  data-testid={`global-auth-checkbox-${tool.id}-${globalRole.id}`}
                  defaultChecked={userHasRole}
                  onChange={() => handleToggleRoleStatus(tool, globalRole, !userHasRole)}
                  label={globalRole.name} /></Col>
              })}
              </Row>
            </Col>
          </Row>
        ))}
    </>
  )
})
