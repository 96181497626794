import React, {FC, ReactElement} from 'react';
import {Link}                     from 'react-router-dom';
import styles from './view-header.module.sass';

interface ILink {
    link    : any;
    text    : string;
    class?  : string;
    icon?   : any;

}

interface PropsType {
    title: string | ReactElement;
    subtitle?: string;
    links?: any;
    customAction?: any;
    breadcrumbs?: ReactElement;
    noUnderline?: boolean;
    backLink?: string | ReactElement;
}

export const ViewHeader: FC<PropsType> = (props) => {

    const linksArray: ReactElement[] = [];

    if(props.links) {

        props.links.forEach((link: ILink) => {

            linksArray.push(
                <Link key={link.text} to={link.link} className={link.class ? link.class : "btn tbn-outline-secondary"}>
                    {link.icon ? link.icon : null} {link.text}
                </Link>
            )
        })
    }

    return (
        <div className={styles.titleHeader}>
            <div className={`${styles.titleHeaderHead} ${props.noUnderline ? styles.noUnderline : ""}`}>
                <h1>{props.title}
                    {props.subtitle && <small>{props.subtitle}</small>}
                </h1>
                <div className={styles.actionsHeader}>
                    {linksArray}
                    {props.customAction && props.customAction}
                </div>
            </div>
            <div className={styles.titleHeaderBody}>
                <div className={styles.breadcrumbs}>{props.breadcrumbs && props.breadcrumbs}</div>
                <div className={styles.backLink}>{props.backLink && props.backLink}</div>
            </div>
        </div>
    )
};
