import {Instance, types}    from "mobx-state-tree";
import {ProjectStore}       from "./project-store";
import {ToolStore}          from "./tool-store";
import {UserStore}          from './user-store';

export const RootStore =  types.model(
    'RootStore',
    {
        toolStore: ToolStore,
        projectStore: ProjectStore,
        userStore: UserStore
    })
    .views(self =>({}))
    .actions(self => ({}));

export interface IRootStore extends Instance<typeof RootStore> {}