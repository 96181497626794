import React, {FC} from 'react';
import {observer}               from 'mobx-react-lite';
import {PortalRouteItems} from '../PortalRouteItems';
import {useProjects, useTools, Projects} from "../../components";
import { CustomSpinner } from '@yakoffice/custom-spinner';
import {ToolsIndex} from "./tools";
import {Routes, Route} from "react-router-dom";

export const ProjectsIndex : FC = observer(() => {

    const loadToolsResult = useTools();
    const loadProjectsResult = useProjects();

    return !loadToolsResult.isLoaded || !loadProjectsResult.isLoaded
        ? <CustomSpinner spinnerText="Loading Projects and Tools..."/>
        : <Routes>
            <Route path={""} element={<Projects />}/>
            <Route path={PortalRouteItems.ProjectIdParam}>
              <Route path={PortalRouteItems.Tools} element={<ToolsIndex />}/>
            </Route>
      </Routes>

})

export default ProjectsIndex;

