
export enum PortalConfigRouteParams{
    ProjectId = ":projectId",
}

export enum PortalRouteItems {
    Projects = "projects",
    ProjectIdParam =":projectId",
    Tools = "tools",
    Users = "users",
    UserParam = ":user",
    Add_User = "add-user"
}
