import React, {FC}              from 'react';
import { ITool } from '../../model/tool';
import { IProject } from '../../model/project';
import { IUser } from '../../model/user';
import { observer } from 'mobx-react-lite';
import Tabs from "react-bootstrap/Tabs";
import Tab from 'react-bootstrap/Tab';
import { UserProjectTabContents } from '../user-project-tab-contents/user-project-tab-contents';
import styles from './user-project-tabs.module.sass'

interface PropTypes{
  projects : IProject[],
  tools: ITool[],
  user: IUser
}

export const UserProjectTabs : FC<PropTypes> = observer(({projects, tools, user}) =>
{
  return (
    <Tabs defaultActiveKey={projects.length > 0 ? projects[0].id : undefined} className={styles.navTabs}>
    {
      projects
        .filter(project => project.tools.length > 0)
        .slice()
        .sort((p1, p2) => p1.name.localeCompare(p2.name))
        .map(project => (
          <Tab key={project.id} eventKey={project.id} title={<span className={``}>{project.name}</span>}>
            <UserProjectTabContents key={project.id} project={project} tools={tools} user={user} />
          </Tab>
          ))
    }
  </Tabs>
  )
})
