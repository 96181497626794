import * as React           from "react";
import {ICustomModalProps, ShowModalAsync} from '../types';
import {CustomModalContext} from '../modal-context';
import { CustomSpinner } from '@yakoffice/custom-spinner';

export const useShowModalAsync : () => ShowModalAsync = () => React.useContext(CustomModalContext);

export const useShowModal : () => (modalValues: ICustomModalProps) => void = () => {
  const showModalAsync = useShowModalAsync()
  return (modalValues: ICustomModalProps) => {showModalAsync<void>(_ => modalValues)};
}

export const useShowModalSpinner : () => (title: string, spinnerBody: string) => void = () => {

  const showModal = useShowModal();

  return (title: string, spinnerBody: string) => {showModal({
    show: true,
    title: title,
    body: <CustomSpinner spinnerText={spinnerBody} position={'relative'} />,
    canClose: false
  })}
}

export const useHideModal : () => () => void = () => {
  const showModal = useShowModal();
  return () => {showModal({show: false});}
}


