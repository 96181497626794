import Popover from 'react-bootstrap/Popover';
import { FC } from 'react';
import React from 'react';
import { IRole } from '../../model/role';
import sass from './role-description-popover.module.sass'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

interface PropTypes{
  roles: IRole[]
}

export const RoleDescriptionPopover : FC<PropTypes> = ({roles}) => {

  const popover = (
    <Popover id="roleDescription">
      <Popover.Header as="h3">Role Descriptions</Popover.Header>
      <Popover.Body>
        {
          roles
            .slice()
            .sort((r1, r2) => r1.name.localeCompare(r2.name))
            .map(role => {
              return <React.Fragment key={role.id}>
                <div ><strong>{role.name}</strong></div>
                <div className="mb-2"><small>{role.description}</small></div>
              </React.Fragment>
            })
        }
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
      <span className={sass.info}><i className={`fa fa-info-circle`} /></span>
    </OverlayTrigger>
  )
}
