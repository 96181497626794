import {useEffect, useState}            from "react";
import { FirebaseApp }                  from '@firebase/app';
import {useYakofficeAuthenticatedUser}  from "../use-yakoffice-authenticated-user";
import {YoAuthUser}                     from "../../types";
import { UserFirebaseApi }              from '../../api';


export interface YakofficeAuthorisedUserResult {
    hasAuthorised: boolean;
    error: Error | null;
    yakofficeUser: YoAuthUser | null;
}


export const useYakofficeAuthorisedUser = (toolId: string, app?: FirebaseApp) : YakofficeAuthorisedUserResult => {

  const [result, setResult] = useState<YakofficeAuthorisedUserResult>(()  => ({
    hasAuthorised: false,
    error: null,
    yakofficeUser: null
  }));
  const authenticationResult = useYakofficeAuthenticatedUser(app)

  useEffect(() => {
    if (authenticationResult.hasAuthenticated && authenticationResult.firebaseUser?.email != null) {
      return UserFirebaseApi()
        .subscribeToUserSnapshots(authenticationResult.firebaseUser.email,
          {
            next: user => {
              if (authenticationResult.firebaseUser != null && user != null) {
                const authUser: YoAuthUser = new YoAuthUser(authenticationResult.firebaseUser, user, toolId);
                setResult({ hasAuthorised: true, error: null, yakofficeUser: authUser });
              } else if(authenticationResult.firebaseUser != null && user == null) {
                setResult({hasAuthorised: true, error: new Error("You have not been give permissions to use Yakoffice.  Please request permissions."), yakofficeUser: null})
              } else {
                // Never going to be called in practice
                setResult({hasAuthorised: false, error: new Error("You have not been authenticated"), yakofficeUser: null})
              }
            },
            error: error => {
              console.error(error)
              setResult({ hasAuthorised: false, error: error, yakofficeUser: null })
            }
          }
        )
    } else if (authenticationResult.hasAuthenticated && authenticationResult.firebaseUser?.email == null){
      setResult({ hasAuthorised: true, error: new Error("You do not have an email setup for your authenticated account"), yakofficeUser: null })
    } else {
      setResult({ hasAuthorised: false, error: null, yakofficeUser: null })
    }
  }, [toolId, authenticationResult.hasAuthenticated, authenticationResult.firebaseUser]);

  return result;
}
