import {useContext, useEffect, useState}            from "react";
import RootStoreContext                             from "../../stores/root-store-context";
import {NotificationType, usePublishNotification}   from "@yakoffice/notification-handler";
import type {IUser}                                      from '../../model/user';

interface useUserResult{
    isLoaded : boolean;
    user     : IUser | null;
}


export const useUser = (user: string | undefined) => {

    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification();

    const [result, setResult] = useState(() => ({
        isLoaded: true,
        user    : null
    } as  useUserResult));

    useEffect(() => {

        setResult({isLoaded: false, user: null});

        if(user) {
            rootStore.userStore.loadUser(user)
                .then(() => {
                    setResult({isLoaded: true, user: rootStore.userStore.users[0]});
                })
                .catch(e => {
                    console.log(e)
                    publishNotification({
                                            title:            "Error Loading User",
                                            notificationType: NotificationType.Error,
                                            message:          e.toString()
                                        });
                })
        } else {
            rootStore.userStore.addUser()
            setResult({isLoaded: true, user: rootStore.userStore.users[0]});
        }
    }, [publishNotification, rootStore.userStore, user]);

    return result;
};
