import * as React           from 'react';
import type {ICustomModalProps} from '../types';
import Modal from 'react-bootstrap/Modal';
import Button               from 'react-bootstrap/Button';
import styles from './custom-modal.module.sass';


export interface CustomModalProps extends ICustomModalProps {
    handleCloseModal    : any;
}

export const CustomModal = (props: CustomModalProps) => {

  const { show, title, body, canClose, closeButtonText, action, handleCloseModal, ...rest } = props;

  return (
    <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered {...rest} className={styles.customModal} data-testid="modal">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        {
          canClose
            ? <Button onClick={handleCloseModal} variant={'warning'} data-testid="btnModalCancelClose">
              {closeButtonText ? closeButtonText : action ? "Cancel" : "Close"}
            </Button>
            : <span className={"mt-5"} />
        }
        {action}
      </Modal.Footer>
    </Modal>
  )
}
