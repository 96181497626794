import {Instance, types} from "mobx-state-tree";
import {GenerateId} from "../utils";
import { Role } from './role';

export const Tool = types.model(
    "Tool",
    {
        id: types.optional(types.identifier, () => GenerateId().toString()),
        name: "",
        projectUrl: "",
        fontawesomeClassname: "",
        globalRoles: types.array(Role),
        projectRoles: types.array(Role),
        gameEnvironmentRoles: types.array(Role),
    });

export interface ITool extends Instance<typeof Tool> {}
