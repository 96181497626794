import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import './index.sass';
import * as serviceWorker from './serviceWorker';
import {rootStore, RootStoreProvider} from "./stores/root-store-context";
import {NotificationHandler} from "@yakoffice/notification-handler";
import {ModalHandler} from '@yakoffice/custom-modal';
import {createRoot} from "react-dom/client";
import {WithAuthentication, WithAuthorisation} from "@yakoffice/with-authorisation";
import {App} from "./components";
import {initYakofficeFirebaseApp} from "@yakoffice/yakoffice-firebase";

initYakofficeFirebaseApp();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <RootStoreProvider value={rootStore}>
      <BrowserRouter>
          <NotificationHandler>
            <ModalHandler>
              <WithAuthentication>
                <WithAuthorisation toolId="portal">
                  <App />
                </WithAuthorisation>
              </WithAuthentication>
            </ModalHandler>
          </NotificationHandler>
      </BrowserRouter>
    </RootStoreProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
