import {useEffect, useState} from "react";
import { ToolFirebaseApi } from '../../api';
import {YoTool} from "../../types";

// Subscribes to YoTool changes in Firestore and updates result accordingly
export const useYoToolsSnapshots = () => {

  const [yoTools, setYoTools] = useState((): YoTool[] => []);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    return ToolFirebaseApi()
      .subscribeToToolsSnapshots({
        next: yoToolsSnapshot => {
          setYoTools(yoToolsSnapshot);
        },
        error: e => {
          console.error(e);
          setError(e)
        }
      })
  }, [])

  return { yoTools, error };
}
