import {useEffect, useState} from "react";
import {YoConfig} from "../../types";
import { ConfigFirebaseApi } from '../../api';

// Subscribes to YoConfig changes in Firestore and updates result accordingly
export const useYoConfigSnapshots = () => {

    const [yoConfig, setYoConfig] = useState(() : YoConfig | null => null);
    const [error, setError]  = useState<Error | null>(null);

    useEffect(() => {
        return ConfigFirebaseApi().subscribeToConfigSnapshots({
            next: yoConfigSnapshot => {setYoConfig(yoConfigSnapshot);},
            error: e => {
                console.error(e);
                setError(e)
            }
        })
    }, [])

    return {yoConfig, error};
}
