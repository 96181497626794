import { Instance, types } from 'mobx-state-tree';
import {GenerateId} from '../utils';
import { IProject } from './project';
import { ITool } from './tool';
import { UserProjectTool} from './user-project-tool';
import { UserProjectTools } from './user-project-tools';
import { UserToolGameEnvironment } from './user-tool-game-environment';
import { UserTool } from './user-tool';


export const User = types.model(
    "User",
    {
        id: types.optional(types.identifier, () => GenerateId().toString()),
        email: "",
        isAdmin: false,
        tools: types.array(UserTool),
        projects: types.array(UserProjectTools),
        isNew: false
})
  .views(self => ({
    isToolEnabledForProject(projectId: string, toolId: string){
      const userProject = self.projects.find(p => p.projectId === projectId);
      return userProject && userProject.tools.find(t => t.toolId === toolId) != null;
    },
    hasGlobalRole(toolId: string, roleId: string){
      return self.tools.find(t => t.toolId === toolId)?.globalRoles.includes(roleId);
    },
    hasProjectRole(projectId: string, toolId: string, roleId: string){
      return self.projects.find(p => p.projectId === projectId)
        ?.tools.find(t => t.toolId === toolId)
        ?.projectRoles.includes(roleId);
    },
    hasGameEnvironmentRole(projectId: string, toolId: string, toolGeId: string, roleId: string){
      return self.projects.find(p => p.projectId === projectId)
        ?.tools.find(t => t.toolId === toolId)
        ?.gameEnvironments?.find(ge => ge.toolGeId === toolGeId)
        ?.roles.includes(roleId);
    }
  }))
  .actions(self => ({
    updateUserEmail(email: string) {
      self.email = email;
    },
    updateUserAdmin(isAdmin: boolean) {
      self.isAdmin = isAdmin;
    },
    updateProjectToolStatus(project: IProject, tool: ITool, enableTool: boolean) {
      let userProject = self.projects.find(p => p.projectId === project.id)
      if (!userProject) {
        userProject = UserProjectTools.create({ projectId: project.id, tools: [] });
        self.projects.push(userProject)
      }

      const userTool = userProject.tools.find(t => t.toolId === tool.id);
      if (enableTool && !userTool) {
        // Denormalise the toolProjectId to prevent the need to search for it again
        const toolMap = project.tools.find(t => t.toolId === tool.id);
        userProject.tools.push(UserProjectTool.create({ toolId: tool.id, toolProjectId: toolMap?.projectId || "" }));
      }
      else if (!enableTool && userTool)
        userProject.tools.remove(userTool);
    },
    setGlobalRoleStatus(toolId: string, roleId: string, enableRole: boolean) {
      let tool = self.tools.find(t => t.toolId === toolId);

      if (!tool) {
        tool = UserTool.create({toolId: toolId, globalRoles: []});
        self.tools.push(tool)
      }

      if(enableRole && !tool.globalRoles.includes(roleId))
        tool.globalRoles.push(roleId);
      else if(!enableRole && tool.globalRoles.includes(roleId))
        tool.globalRoles.remove(roleId)
    },
    setProjectRoleStatus(projectId: string, toolId: string, roleId: string, enableRole: boolean) {
      const tool = self.projects.find(p => p.projectId === projectId)?.tools.find(t => t.toolId === toolId);

      if (!tool)
        throw new Error(`Cannot update project roles.  Tool (${toolId}) is not enabled for project (${projectId})`)

      if(enableRole && !tool.projectRoles.includes(roleId))
        tool.projectRoles.push(roleId);
      else if(!enableRole && tool.projectRoles.includes(roleId))
        tool.projectRoles.remove(roleId)
    }
    ,
    setGameEnvironmentRoleStatus(projectId: string, toolId: string, toolGeId: string, roleId: string, enableRole: boolean) {
      const tool = self.projects.find(p => p.projectId === projectId)?.tools.find(t => t.toolId === toolId);

      if (!tool)
        throw new Error(`Cannot update game environment roles.  Tool (${toolId}) is not enabled for project (${projectId})`)

      let gameEnvironment = tool.gameEnvironments?.find(ge => ge.toolGeId === toolGeId)

      if (!gameEnvironment) {
        gameEnvironment = UserToolGameEnvironment.create({toolGeId: toolGeId, roles: []});
        tool.gameEnvironments.push(gameEnvironment)
      }

      if(enableRole && !gameEnvironment.roles.includes(roleId))
        gameEnvironment.roles.push(roleId);
      else if(!enableRole && gameEnvironment.roles.includes(roleId))
        gameEnvironment.roles.remove(roleId)
    }
  }));

export interface IUser extends Instance<typeof User> {}


