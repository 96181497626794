
export const YakofficeFirestoreConfigPath = "yakoffice/auth";

export const yakofficeFirebaseConfig = {
  dev: {
    apiKey: "AIzaSyBHfBS7DX2zOu4XQfsJjo2ewc1iXuWqep8",
    authDomain: "yakuto-cloud-dev.firebaseapp.com",
    databaseURL: "https://yakuto-cloud-dev.firebaseio.com",
    projectId: "yakuto-cloud-dev",
    storageBucket: "yakuto-cloud-dev.appspot.com",
    messagingSenderId: "1008223424402",
    appId: "1:1008223424402:web:c6ad45c7945bd5a5d6bb4b"
  },

  prd: {
    apiKey: "AIzaSyBUkUykzJiGGsN5s2UQ4dTRi8YTH42IIUY",
    authDomain: "yakuto-cloud.firebaseapp.com",
    databaseURL: "https://yakuto-cloud.firebaseio.com",
    projectId: "yakuto-cloud",
    storageBucket: "yakuto-cloud.appspot.com",
    messagingSenderId: "548558671863",
    appId: "1:548558671863:web:6413a376ec7323fb"
  }
};
