import React, { FC } from 'react';
import { ITool } from '../../model/tool';
import Form from 'react-bootstrap/Form';
import { LabelHeader } from '@yakoffice/shared-components';

interface PropTypes {
  tool: ITool
}

export const ToolLabel : FC<PropTypes> = ({tool}) => {
  return <Form.Label>
              <LabelHeader>
                <i className={`fa ${tool.fontawesomeClassname}`} /> {tool.name}
              </LabelHeader>
        </Form.Label>
}
