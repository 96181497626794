import {useContext, useEffect, useState}            from "react";
import RootStoreContext                             from "../../stores/root-store-context";
import {NotificationType, usePublishNotification}   from "@yakoffice/notification-handler";
import type {IUser}                                      from '../../model/user';

interface useUsersResult{
    isLoaded : boolean;
    users : IUser[] | null
}

export const useUsers = () => {

    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification()

    const [result, setResult] = useState(() => ({
                                    isLoaded: true,
                                    users   : []
                                } as  useUsersResult));

    useEffect(() => {

        setResult({isLoaded: false, users: []});

        rootStore.userStore.loadUsers()
            .then(() => {
                setResult({isLoaded: true, users: rootStore.userStore.users});
            })
            .catch(e => {
                       console.log(e)
                       publishNotification({
                                               title: "Error Loading Users",
                                               notificationType: NotificationType.Error,
                                               message: e.toString()
                                           });
                   }
            )
    }, [publishNotification, rootStore.userStore]);

    return result;
};
