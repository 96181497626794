import React, {FC, useContext}  from 'react';
import RootStoreContext from "../../../stores/root-store-context";
import {IconCard} from '@yakoffice/icon-card'
import { useParams } from 'react-router-dom';
import {AuthUserContext} from "@yakoffice/yakoffice-firebase";
import {PortalConfigRouteParams} from "../../PortalRouteItems";
import { CenteredPanel } from '@yakoffice/shared-components';
import Container from 'react-bootstrap/cjs/Container';
import Row from 'react-bootstrap/cjs/Row';
import Col from 'react-bootstrap/cjs/Col';
import { observer } from 'mobx-react-lite';

export const ToolsIndex : FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const authUser = useContext(AuthUserContext);
    const { projectId } = useParams<{projectId: string}>()

    const project = rootStore.projectStore.projects.find(p => p.id === projectId);
    const authorisedProject = authUser?.yoUser.projects.find(authProject => authProject.projectId === projectId);

    return <CenteredPanel id="projects">
            <Container>
                <Row className="justify-content-center">
                    {
                        authUser &&
                        project &&
                        authorisedProject &&
                        project.tools
                            .filter(toolMap => authUser.isAuthorisedForTool(project.id, toolMap.toolId))
                            .map(toolMap => {
                                const tool = rootStore.toolStore.tools.find(t => t.id === toolMap.toolId)
                                return tool && <Col xs="12" sm="12" md="4" key={tool.id}>
                                    <IconCard
                                        icon={<i className={`fas ${tool.fontawesomeClassname && tool.fontawesomeClassname}`} />}
                                        title={tool.name}
                                        link={`${tool.projectUrl.replace(PortalConfigRouteParams.ProjectId,toolMap.projectId)}`}
                                        isExternalLink={true}
                                        data-testid="toolIconCard"
                                    />
                                </Col>
                            }
                        )
                    }
                </Row>
            </Container>
        </CenteredPanel>
})
