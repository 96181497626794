import * as React from 'react';
import {FC} from 'react';
import Button                                   from "react-bootstrap/Button";
import {INotificationData, NotificationType} from "../types";
import {Notification} from '../notification';
import styles from './notifications.module.sass'

interface PropsType {
    notifications: INotificationData[],
    handleDeleteNotification: (notification: INotificationData) => void,
    handleClearNotifications: () => void
}

export const Notifications : FC<PropsType> = (props) =>{

    return (
        <div className={styles.notificationController}>
            {props.notifications.map(notification =>
                <Notification
                    key={`toast-${props.notifications.indexOf(notification)}`}
                    notification={notification}
                    deleteNotification={props.handleDeleteNotification}/>
            )}

            {props.notifications.length > 1 &&
                <Notification
                    key={`toast-clear-all`}
                    notification={{
                        notificationType : NotificationType.Info,
                        message :
                            <div className="text-center">
                                <Button variant="primary" onClick={() => props.handleClearNotifications()}>
                                    Clear All Notifications
                                </Button>
                            </div>
                        }}
                    deleteNotification={props.handleDeleteNotification} />
            }
        </div>
    )

}
