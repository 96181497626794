import React, {useContext}                  from "react";
import Button                               from 'react-bootstrap/Button';
import {CustomSpinner}                      from '@yakoffice/custom-spinner';
import RootStoreContext                     from '../stores/root-store-context';
import {useShowModal}                       from '@yakoffice/custom-modal';
import { useNavigate } from "react-router-dom";
import {PortalRouteItems} from "../routes/PortalRouteItems";


export interface IUserController {
    saveUser: () => void,
    deleteUser: () => void,
}

interface useUserControllerProps {
    setUserEditStatus: (edited: boolean) => void;
}

export const UserController = (props: useUserControllerProps) => {

    // Context Hooks
    const rootStore = useContext(RootStoreContext);
    const showModal = useShowModal();
    const navigate = useNavigate();


    // // Handlers
    const redirectToUsers = () => {
        navigate("/"+PortalRouteItems.Users);
    };

    const saveUser = () => {

        const confirmSaveUser = () => {

            showModal(
                {
                    show:     true,
                    title:    "Save User",
                    body:     <CustomSpinner spinnerText={`Saving user`} position={'relative'}/>,
                    canClose: false
                }
            );

            rootStore.userStore.saveUser()
                .then( () => {
                    props.setUserEditStatus(false);
                    showModal({body: "", canClose: false, title: '', show: false});
                    redirectToUsers();
                })
                .catch(e => {
                    showModal({
                        show: true,
                        title: 'Error Saving User',
                        body: "Error Message: " + e.toString(),
                        canClose: true,
                    });
                });
        };

        showModal(
            {
                show:     true,
                title:    "Save User",
                body:     "Are you sure you want to save this user?",
                action:   <Button variant="success" onClick={() => confirmSaveUser()}>Yes</Button>,
                canClose: true
            }
        );

    };

    const deleteUser = () => {

        const confirmDeleteUser = () => {

            showModal(
                {
                    show:     true,
                    title:    "Delete User",
                    body:     <CustomSpinner spinnerText={`Deleting user`}  position={'relative'}/>,
                    canClose: false
                }
            );

            rootStore.userStore.deleteUser()
                .then( () => {
                    props.setUserEditStatus(false);
                    showModal({body: "", canClose: false, title: '', show: false});
                    redirectToUsers();
                })
                .catch(e => {
                    showModal({
                        show: true,
                        title: 'Error Deleting User',
                        body: "Error Message: " + e.toString() ,
                        canClose: true,
                    });
                });
        };

        showModal(
            {
                show:     true,
                title:    "Delete User",
                body:     "Are you sure you want to delete this user?",
                action:   <Button variant="success" onClick={() => confirmDeleteUser()}>Yes</Button>,
                canClose: true
            }
        );

    };

    const controller : IUserController = {
        saveUser,
        deleteUser
    };

    return controller;
};
