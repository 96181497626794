import {Instance, types} from "mobx-state-tree";
import {GenerateId} from "../utils";
import {ProjectToolMap} from "./project-tool-map";

export const Project = types.model(
    "Project",
    {
            id: types.optional(types.identifier, () => GenerateId().toString()),
            name: "",
            imageUrl: types.maybeNull(types.string),
            tools: types.array(ProjectToolMap)
    });

export interface IProject extends Instance<typeof Project> {}
