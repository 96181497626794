import * as React                     from "react";
import {FC, PropsWithChildren, useEffect, useState} from "react";
import {NavLink}                      from 'react-router-dom';
import { Navbar, Nav, NavDropdown}    from 'react-bootstrap';
import Accordion                      from 'react-bootstrap/cjs/Accordion';
import Button                         from 'react-bootstrap/cjs/Button';
import { getAuth }                    from "firebase/auth";
import { RouteParams, sortByString }  from '@yakoffice/yakoffice-firebase';
import {useYakofficeHeader}           from "./use-yakoffice-header";
import styles                         from './yakoffice-header.module.sass'


interface PropsType{
    // If hosted in a tool then ignore or set to false.  If hosted in Portal then set to true
    hostedInPortal?: boolean;

    // Is not required to be set in deployed environments.  Set it when you're running in localhost because you cannot tell which tool it is from the URL
    hostedInToolId?: string;
}


export const YakofficeHeader : FC<PropsWithChildren<PropsType>> = (props) => {

    const {authUser, yoConfig, yoProjects, yoTools, selectedProject, selectedTool, error, isDevSite} = useYakofficeHeader(!!props.hostedInPortal, props.hostedInToolId || null)
    const [isMobile] = useState(document.documentElement.clientWidth < 768);
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        if (isMobile)
          setCollapsed(true);
        if (error != null)
            throw error;
    }, [error, isMobile]);

    const adjustRoute = (route: string) => {
        return props.hostedInPortal ? route : (yoConfig?.portalUrl + route);
    }

    const projectColor = () => {

      if(selectedProject)
        return {
        background: `linear-gradient(90deg, #${selectedProject.color} 0%, rgba(6,30,51,1) 80%)`
        };
      else
        return {};
    }

    const backgroundImage = () => {

      if(selectedProject)
        return <div className={styles.yoBackgroundImage} style={{ backgroundImage: `url(${selectedProject.backgroundImageUrl})` }}> </div>
      else
        return null;
    }

    return (authUser && yoConfig &&
      <div>
        <Accordion activeKey={collapsed ? "" : "0"} className={styles.yoHeader}>
          <Accordion.Collapse eventKey={"0"}>
            <Navbar expand={true} style={projectColor()}>
              <Navbar.Brand href={adjustRoute(yoConfig.portalProjectsRoute)}>
                <img key="webLogo" src={adjustRoute(yoConfig.portalWeblogoRoute)} alt="YakOffice"
                     className={styles.yoHeaderLogo}/>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="projectsNavToggle" />
              {isDevSite() && <code className="ms-auto me-auto text-white d-block d-md-none mb-3">DEVELOPMENT SITE</code>}
              <Navbar.Collapse className="justify-content-end">
                {isDevSite() && <code className="ms-auto me-0 text-white d-none d-md-block">DEVELOPMENT SITE</code>}
                <Nav className="ms-auto">
                  <NavDropdown id="projectsNav" title={selectedProject ? selectedProject.name : "Select Project"}>
                    {yoProjects.sort(sortByString("name"))
                      .filter(p => authUser.isAuthorisedForProject(p.id))
                      .map( project => {
                        const adjustedRoute = adjustRoute(yoConfig?.portalToolsRoute.replace(RouteParams.ProjectId, project.id));
                        return props.hostedInPortal
                               ?<NavLink to={adjustedRoute} className="dropdown-item" key={project.id}>{project.name}</NavLink>
                               :<Nav.Link href={adjustedRoute} key={project.id} className="dropdown-item">{project.name}</Nav.Link>
                      })}
                  </NavDropdown>
                  {selectedProject &&
                   <NavDropdown id="toolsNav" title={selectedTool ? selectedTool.name : "Select App"}>
                     {selectedProject.tools
                       .filter(toolMap => authUser.isAuthorisedForTool(selectedProject.id, toolMap.toolId))
                       .map(toolMap => {
                              const tool = yoTools.find(t => t.id === toolMap.toolId)
                              return tool &&
                                     <Nav.Link className="dropdown-item" href={tool.projectUrl.replace(RouteParams.ProjectId, toolMap.toolProjectId)} key={tool.id}>{tool.name}</Nav.Link>
                            }
                       )
                     }
                   </NavDropdown>
                  }
                  {authUser.yoUser.isAdmin &&
                   <Nav.Link id="usersNav" href={adjustRoute(yoConfig.portalUsersRoute)}>Users</Nav.Link>
                  }
                  <NavDropdown id="profileNav"
                               className={`${styles.navItemLeftBorder}`}
                               align="end"
                               title={
                                 <>
                                   <span>{authUser.firebaseUser.displayName}</span>
                                   {authUser.firebaseUser.photoURL
                                    ? <img src={authUser.firebaseUser.photoURL} alt={""} className={styles.yoHeaderUserImage} />
                                    : <i className="fas fa-user-tie" />}
                                 </>
                               }>
                    <NavDropdown.Item href={yoConfig.portalUrl} onClick={() => getAuth().signOut()}>Sign out</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item disabled>Version: {process.env.REACT_APP_GIT_BRANCH ? process.env.REACT_APP_GIT_BRANCH : "Not Set"}</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Accordion.Collapse>
          <Accordion.Button as={Button} variant="link" onClick={() => setCollapsed(!collapsed)} className={styles.yoHeaderToggle} />
        </Accordion>

        <div className={styles.yoBackgroundImageWrapper}>
          {backgroundImage()}
          {props.children}
        </div>
      </div>
    )
}

