import * as React from "react";
import {FC, PropsWithChildren} from "react";
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
import {usePublishNotification} from "../use-publish-notification";
import {NotificationType} from "../types";

interface PropsType{
    errorTitle: string
    fallbackComponent?: React.ComponentType<FallbackProps>
}

// Provides an error boundary that will publish a notification.  Can provide a fallbackComponent if desired;
export const NotificationErrorBoundary : FC<PropsWithChildren<PropsType>> = ({ errorTitle, fallbackComponent = () => { return null}, ...props} ) =>
{
    const publishNotification = usePublishNotification();

    const errorHandler = (error: Error) => {
        console.error(error);
        publishNotification({
            notificationType: NotificationType.Error,
            title: errorTitle,
            message: error.toString()
        })
    }

    return  (
        <ErrorBoundary FallbackComponent={fallbackComponent} onError={errorHandler}>
            {props.children}
        </ErrorBoundary>
    )
}
