import React, {FC, ReactElement} from 'react';
import { Link } from 'react-router-dom';
import styles from  './icon-card.module.sass'
import Card from 'react-bootstrap/cjs/Card';

interface PropsType {
    icon?   : ReactElement
    title?  : string
    body?   : string | ReactElement
    link    : string
    isExternalLink?: boolean
    className? : string
}

export const IconCard : FC<PropsType> = ({icon, title, body, link, isExternalLink, className, ...props}) => {


    const makeLink = (content: any) => {
      return isExternalLink ? <a href={link}>{content}</a> : <Link to={link}>{content}</Link>;
    }


    return (
        <Card className={`${styles.iconCard} ${className ?? ""}`} {...props}>
            <Card.Body>
              {makeLink( <>
                {icon && <div className={styles.icon}>{icon}</div>}
                <Card.Title>
                  {title && title}
                </Card.Title>
                {body && <div>{body}</div>}
              </>)}
            </Card.Body>
        </Card>
    )
}

