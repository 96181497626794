import {useContext, useEffect, useState} from "react";
import RootStoreContext from "../../stores/root-store-context";
import type {ITool} from "../../model/tool";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";

interface useToolsResult{
    isLoaded : boolean;
    error: Error | null;
    tools    : ITool[] | null
}

export const useTools = () => {

    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification()

    const [result, setResult] = useState(() => ({
        isLoaded: true,
        error: null,
        tools   : []} as  useToolsResult));

    useEffect(() => {
            setResult({isLoaded: false, error: null, tools: []});

            rootStore.toolStore.loadTools()
                .then(() => {
                    setResult({isLoaded: true, error: null, tools: rootStore.toolStore.tools});
                })
                .catch(e => {
                        console.log(e);
                        publishNotification({
                            title: "Error Loading Tools",
                            notificationType: NotificationType.Error,
                            message: e.toString()
                        });
                        setResult({isLoaded:false, error: e, tools: []})
                    }
                )
    }, [publishNotification, rootStore.toolStore]);

    return result;
};
