import {useContext, useEffect, useState} from "react";
import RootStoreContext from "../../stores/root-store-context";
import type {IProject} from "../../model/project";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";

interface useProjectsResult{
    isLoaded : boolean;
    projects    : IProject[] | null
}

export const useProjects = () => {

    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification()

    const [result, setResult] = useState(() => ({
        isLoaded: true,
        projects   : []} as  useProjectsResult));

    useEffect(() => {
            setResult({isLoaded: false, projects: []});

            rootStore.projectStore.loadProjects()
                .then(() => {
                    setResult({isLoaded: true, projects: rootStore.projectStore.projects});
                })
                .catch(e => {
                        console.log(e)
                        publishNotification({
                            title: "Error Loading Projects",
                            notificationType: NotificationType.Error,
                            message: e.toString()
                        });
                    }
                )
    }, [publishNotification, rootStore.projectStore]);

    return result;
};
