import React, { FC } from 'react';
import type {IUser}      from '../../model/user';
import type {IProject} from '../../model/project';
import type {ITool} from '../../model/tool';
import { UserProjectToolRolesPopover } from '../../components/user-project-tool-roles-popover/user-project-tool-roles-popover';
import { YoTable } from '@yakoffice/shared-components';
import {useNavigate} from "react-router-dom";

interface PropsType {
    projects: IProject[];
    tools   : ITool[];
    users   : IUser[];
}

export const UsersTable : FC<PropsType> = ({ projects, tools, users }) => {

    const navigate = useNavigate();

    const redirectToUser = (user: IUser) => navigate(user.email);

    return (
        <YoTable responsive hover>
            <thead className="">
                <tr>
                    <th>Email</th>
                    <th>Admin</th>
                    <th className="width-200px">Projects</th>
                    <th>Tools</th>
                </tr>
            </thead>
            <tbody>
            {users.map(user => {

              return (
                    <tr key={user.email} onClick={() => redirectToUser(user)} className="linked-row">
                        <td>{user.email}</td>
                        <td>{user.isAdmin ? "Yes" : "No"}</td>
                        <td colSpan={2}>
                            <YoTable borderless className="bg-transparent">
                                <tbody>
                                    {user.projects
                                      .slice()
                                      .sort((up1, up2) => up1.projectId.localeCompare(up2.projectId))
                                      .map(userProject => {
                                        const project = projects.find(({id}) => id === userProject.projectId)
                                        return (project &&
                                                <tr key={project.id} >
                                                    <td className="width-200px">{project.name}</td>
                                                    <td>
                                                        {
                                                          userProject.tools
                                                            .slice()
                                                            .sort((t1,t2) => t1.toolId.localeCompare(t2.toolId))
                                                            .map(userProjectTool => <UserProjectToolRolesPopover key={userProjectTool.toolId} user={user} userProjectTools={userProject} userProjectTool={userProjectTool} projects={projects} tools={tools} />)
                                                            .map((item, index) => [index > 0 && ', ', item ])
                                                        }
                                                    </td>
                                                </tr>
                                        )
                                    })}
                                </tbody>
                            </YoTable>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </YoTable>
    )
}

export default UsersTable;


