import * as React           from "react";
import {FC, PropsWithChildren, useRef, useState} from "react";
import {ICustomModalProps, ModalState, ShowModalAsync} from './types';
import {CustomModalContext} from './modal-context';
import {CustomModal}        from './modal';

export const ModalHandler : FC<PropsWithChildren> = ({children}) => {

    const [customModalProps, setCustomModalProps] = useState<ICustomModalProps>({} as ICustomModalProps);
    const promiseResolve = useRef<((modalState: ModalState) => void) | null>(null);

    const showModalAsync : ShowModalAsync = (buildModalProps) => {
      return new Promise(resolve => {

        promiseResolve.current = resolve;

        const modalProps = buildModalProps(result => {
          promiseResolve.current = null;
          resolve(result)
        })

        if(!modalProps.show)
          promiseResolve.current = null;

        setCustomModalProps(modalProps);
      })
    }

    const closeModalAsync = () => {
        setCustomModalProps({show: false} as ICustomModalProps);
        if(promiseResolve.current){
          promiseResolve.current(ModalState.Closed);
          promiseResolve.current = null;
        }
    }

    return (
        <CustomModalContext.Provider value={showModalAsync}>
            {children}
            <CustomModal {...customModalProps} handleCloseModal={closeModalAsync} />
        </CustomModalContext.Provider>
    )
}
