import { Instance, types } from 'mobx-state-tree';
import { UserProjectTool } from './user-project-tool';

export const UserProjectTools = types.model(
  "UserProjectTools",
  {
    projectId: types.string,
    tools: types.array(UserProjectTool)}
)

export interface IUserProjectTools extends Instance<typeof UserProjectTools> {}
