import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {CenteredPanel} from "@yakoffice/shared-components";
import {onAuthStateChanged} from "firebase/auth";
import styles from './with-authentication.module.sass'
import defaultLogo from './YakOffice.svg';
import 'firebaseui/dist/firebaseui.css';
import {Container, Row} from "react-bootstrap";
import {FirebaseAuthenticator} from "./firebase-authenticator";

interface PropTypes
{
  yoLogoImagePath?: string
  redirectUrl?: string
}

export const WithAuthentication : FC<PropsWithChildren<PropTypes>> = ({yoLogoImagePath, redirectUrl, children}) => {

  const [hasReceivedAuthState, setHasReceivedAuthState] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    return onAuthStateChanged(firebase.auth(), (user) => {
      setHasReceivedAuthState(true);
      setIsSignedIn(!!user);
    });
  }, [redirectUrl, isSignedIn, setIsSignedIn]);


  return !isSignedIn
    ? <CenteredPanel className={styles.panel}>
      <Container>
        <Row className={styles.row}>
          <img src={yoLogoImagePath ?? defaultLogo}
               alt="YakOffice"
               style={{"width": "250px", "marginBottom": "50px"}}
               className={styles.signInLogo}/>
        </Row>
        <Row className={styles.row}>
          {hasReceivedAuthState && <FirebaseAuthenticator/>}
        </Row>
      </Container>
    </CenteredPanel>
    // eslint-disable-next-line react/jsx-no-useless-fragment
    : <>{children}</>
}


