import { Instance, types } from 'mobx-state-tree';


export const UserToolGameEnvironment = types.model(
  "UserToolGameEnvironment",
  {
    toolGeId: types.string,
    roles: types.array(types.string)
  }
)

export interface IUserToolGameEnvironment extends Instance<typeof UserToolGameEnvironment> {}
