import {flow, Instance, types} from "mobx-state-tree";
import type {IProject} from "../model/project";
import {Project} from "../model/project";
import { ProjectFirebaseApi, YoProject, YoProjectToolMap } from '@yakoffice/yakoffice-firebase';

const projectFirebaseApi = ProjectFirebaseApi();

export const ProjectStore = types.model(
    "ProjectStore",
    {
        projects: types.array(Project),
    })
    .views(self => ({
        getCurrentProject() : IProject{
            return self.projects[0];
        }
    }))
    .actions(self => ({
        loadProjects : flow(function*() {
            try {

              const firebaseProjects = yield projectFirebaseApi.getProjectsConfig();

                const projects = firebaseProjects.map((firebaseProject: YoProject) => Project.create(MapToProjectModel(firebaseProject)))
                self.projects.replace(projects);
            } catch (e: any) {
                throw new Error(`Failed to load projects: ${e.message}`);
            }})
    }));

const MapToProjectModel = (project: YoProject) => {
    return {
        id: project.id,
        name: project.name,
        imageUrl: project.imageUrl,
        tools:  project.tools.map((projectTool: YoProjectToolMap) => {
          return {
            toolId: projectTool.toolId,
            projectId: projectTool.toolProjectId,
            gameEnvironments: projectTool.gameEnvironments?.map(toolGameEnvironment => {
              return {
                name: toolGameEnvironment.name,
                toolGeId: toolGameEnvironment.toolGeId
              }
            }) || []
          }})
    }
};

export interface IProjectStore extends Instance<typeof ProjectStore> {}
