import * as React                                    from 'react';
import Toast                                    from "react-bootstrap/Toast";
import {INotificationData, NotificationType} from "../types";
import styles from './notification.module.sass'

const iconMap = new Map();
iconMap.set(NotificationType.Info, <i className='fas fa-exclamation text-info' />);
iconMap.set(NotificationType.Warn, <i className='fas fa-exclamation-triangle text-warning' />);
iconMap.set(NotificationType.Error, <i className='fas fa-exclamation-circle text-danger' />);

const titleMap = new Map();
titleMap.set(NotificationType.Info, "Information");
titleMap.set(NotificationType.Warn, "Warning");
titleMap.set(NotificationType.Error, "Error");

export interface IToastProps{
    notification: INotificationData,
    deleteNotification: (n: INotificationData) => void
}

export const Notification = (props: IToastProps) => {

    return (
      <Toast className={`${styles.toast} mb-2`} onClose={() => props.deleteNotification(props.notification)} data-testid="toast">
          <Toast.Header closeVariant="white">
              {iconMap.get(props.notification.notificationType)}
              <strong className="me-auto ms-1">{titleMap.get(props.notification.notificationType)}</strong>
          </Toast.Header>
          <Toast.Body>
              <div className="fw-bold mb-1">{props.notification.title}</div>
              <div className="mb-2">{props.notification.message}</div>
              <div>{props.notification.footer}</div>
          </Toast.Body>
      </Toast>
    )
};
