import 'firebase/auth';
import 'firebase/firestore';
import { YoObserver, YoUser } from '../types';
import { FirebaseAPI } from './firebase-api';
import { YakofficeFirestoreConfigPath } from '../utils';
import { MapDocToUser } from './mappings/map-doc-to-user';
import {DocumentData} from "firebase/firestore";

const firebaseAPI = FirebaseAPI();

export const UserFirebaseApi = () => {

  const usersPath = `${YakofficeFirestoreConfigPath}/users`;

  const getUser = async (email: string): Promise<YoUser> => {
    return await firebaseAPI.getCollectionItem(usersPath, email, MapDocToUser);
  }

  const subscribeToUserSnapshots = (email: string, observer: YoObserver<YoUser|undefined>) : (() => void) => {
    const MapDocToUserOrUndefined = (id: string, data: DocumentData | undefined) => data && MapDocToUser(id,data);
    return firebaseAPI.subscribeToDocSnapshots(`${usersPath}/${email}`, MapDocToUserOrUndefined, observer);
  }

  const getUsers = async (): Promise<YoUser[]> => {
    return await firebaseAPI.getCollectionItems(usersPath, MapDocToUser)
  }

  const saveUser = async (user: YoUser): Promise<void> => {
    const {email, ...rest} = user;
    await firebaseAPI.saveCollectionItem(usersPath, user.email, rest)
  }

  const deleteUser = async (email: string): Promise<void> => {
    await firebaseAPI.deleteCollectionItem(usersPath, email)
  }

  return {
    getUser,
    subscribeToUserSnapshots,
    getUsers,
    saveUser,
    deleteUser
  }
}
