import 'firebase/auth';
import 'firebase/firestore';
import {YakofficeFirestoreConfigPath} from "../utils";
import { YoConfig, YoObserver } from '../types';
import { FirebaseAPI } from './firebase-api';
import { DocMapping } from './mappings/doc-mapping';

const firebaseAPI = FirebaseAPI();

// Configuration API from Firestore which describes URLs for the Portal App
export const ConfigFirebaseApi = () => {

  const configPath = YakofficeFirestoreConfigPath;

  const mapToConfig : DocMapping<YoConfig> = (id, data) => {
    return { ...data } as YoConfig
  };

  const getConfig = async (): Promise<YoConfig> => {
    return firebaseAPI.getDocItem(configPath, mapToConfig)
  }

  // Subscribes an observer to YoConfig snapshot updates from Firestore
  const subscribeToConfigSnapshots = (observer: YoObserver<YoConfig>) : (() => void) => {
    return firebaseAPI.subscribeToDocSnapshots(configPath, mapToConfig, observer);
  }

  return {
    getConfig,
    subscribeToConfigSnapshots
  }

}
