import React, {FC}  from 'react';
import {observer} from 'mobx-react-lite';
import { CustomSpinner }        from '@yakoffice/custom-spinner';
import {useUser, useProjects, useTools, UserForm} from '../../../components';
import {useParams} from 'react-router-dom';

export const UserIndex : FC = observer(() => {

    const {user} = useParams<{user: string}>();
    const loadProjectsResult    = useProjects();
    const loadToolsResult       = useTools();
    const loadUserResult        = useUser(user);

    return !loadUserResult.isLoaded || !loadProjectsResult.isLoaded || !loadToolsResult.isLoaded
           ? <CustomSpinner spinnerText="Loading User..."/>
           :  loadUserResult.user &&
              loadProjectsResult.projects &&
              loadToolsResult.tools &&
                <UserForm projects={loadProjectsResult.projects} tools={loadToolsResult.tools} user={loadUserResult.user}/>
})

export default UserIndex;

