import * as React from 'react';
import {FC} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {PortalRouteItems} from "../../routes/PortalRouteItems";
import {default as ProjectsIndex} from "../../routes/projects";
import UsersIndex from "../../routes/users";
import {observer} from 'mobx-react-lite';
import { NotificationErrorBoundary } from '@yakoffice/notification-handler';
import UserIndex from '../../routes/users/user';
import { YakofficeHeader } from '@yakoffice/yakoffice-header';
import {joinRoute, matchDeeply} from "@yakoffice/shared-components";

export const App : FC = observer(() => {

  return <div id="app">
    <NotificationErrorBoundary errorTitle="Error Loading Yakoffice Header">
      <YakofficeHeader hostedInPortal={true}>
        <Routes>
          <Route path="/" element={<Navigate to={PortalRouteItems.Projects}/>}/>
          <Route path={matchDeeply(PortalRouteItems.Projects)} element={<ProjectsIndex/>}/>
          <Route path={PortalRouteItems.Users} element={<UsersIndex/>}/>
          <Route path={joinRoute(PortalRouteItems.Users, PortalRouteItems.Add_User)} element={<UserIndex/>}/>
          <Route path={joinRoute(PortalRouteItems.Users, PortalRouteItems.UserParam)} element={<UserIndex/>}/>
        </Routes>
      </YakofficeHeader>
    </NotificationErrorBoundary>
  </div>
})
