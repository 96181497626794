import Popover from 'react-bootstrap/Popover';
import { FC } from 'react';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Col, Row } from 'react-bootstrap';
import { IUser } from '../../model/user';
import { IUserProjectTool } from '../../model/user-project-tool';
import { IProject } from '../../model/project';
import { ITool } from '../../model/tool';
import { IUserProjectTools } from '../../model/user-project-tools';

interface PropTypes{
  user: IUser,
  userProjectTools : IUserProjectTools,
  userProjectTool: IUserProjectTool,
  tools   : ITool[];
  projects: IProject[];
}

export const UserProjectToolRolesPopover : FC<PropTypes> = ({user, userProjectTools, userProjectTool, projects, tools}) => {

  const getGlobalRolesDescription = (tool: ITool) => {
    const userTool = user.tools.find(userTool => userTool.toolId === tool.id);
    return userTool &&
      userTool.globalRoles.length > 0 && userTool.globalRoles.map(roleId => tool.globalRoles.find(role => role.id === roleId)?.name).join(", ")
  }

  const getProjectRolesDescription = (tool: ITool) => {
    return userProjectTool.projectRoles.length > 0 &&
      userProjectTool.projectRoles.map(roleId => tool.projectRoles.find(role => role.id === roleId)?.name).join(", ")
  }

  const getGeRolesDescription = (tool : ITool) => {
    const geWithRoles = userProjectTool.gameEnvironments.find(uge => uge.roles.length > 0);
    return geWithRoles &&
      userProjectTool.gameEnvironments
      .map(userToolGameEnvironment => {
          const projectTool = projects.find(p => p.id === userProjectTools.projectId)?.tools.find(t => t.toolId === tool.id);
          const projectToolGe = projectTool?.gameEnvironments.find(ge => ge.toolGeId === userToolGameEnvironment.toolGeId);
          return userToolGameEnvironment.roles.length > 0 &&
          (
            <div key={userToolGameEnvironment.toolGeId}>
              {projectToolGe?.name + ": (" + userToolGameEnvironment.roles.map(roleId => tool.gameEnvironmentRoles.find(role => role.id === roleId)?.name).join(", ") + ")"}
            </div>
          )
        }
      )
  }

  const buildPopover = (tool: ITool) => {
    const globalRolesDescription = getGlobalRolesDescription(tool);
    const projectRolesDescription = getProjectRolesDescription(tool);
    const geRolesDescription = getGeRolesDescription(tool);

    return (
      <Popover id="roleDescription" className="mw-100">
        <Popover.Header as="h3">User Roles</Popover.Header>
        <Popover.Body>
          {!globalRolesDescription && !projectRolesDescription && !geRolesDescription && "None"}
          {globalRolesDescription &&
          <Row key="globalRoles" className="pb-2 mb-2 border-bottom">
            <Col md={'auto'} className="fw-bold">Global Roles: </Col>
            <Col>{globalRolesDescription}</Col>
          </Row>
          }
          {projectRolesDescription &&
          <Row key="projectRoles" className="pb-2 mb-2 border-bottom">
            <Col md={'auto'} className="fw-bold">Project Roles: </Col>
            <Col>{projectRolesDescription}</Col>
          </Row>
          }
          {geRolesDescription &&
          <Row key="geRoles" className="pb-2 mb-2 border-bottom">
            <Col md={'auto'} className="fw-bold">Game Environment Roles:</Col>
            <Col>{geRolesDescription}</Col>
          </Row>
          }
        </Popover.Body>
      </Popover>
    );
  }

  const tool = tools.find(({ id }) => id === userProjectTool.toolId);

  return tool
    ?<OverlayTrigger placement="right" overlay={buildPopover(tool)}>
      <span>{tool.name}</span>
    </OverlayTrigger>
    : null
}
