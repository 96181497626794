import { flow, getRoot, Instance, types } from 'mobx-state-tree';
import { IUser, User } from '../model/user';
import {
  UserFirebaseApi,
  YoUserProjectToolsMap,
  YoUser,
  YoUserProjectTool,
  YoUserProjectToolGe,
  YoUserTool
} from '@yakoffice/yakoffice-firebase';
import { RootStore } from './root-store';
import { ITool } from '../model/tool';
import { IProject } from '../model/project';

const userFirebaseAPI = UserFirebaseApi();

export const UserStore = types.model(
    "UserStore",
    {
        users:      types.array(User),
    })
    .actions(self => ({
        addUser() {
            const user = User.create({isNew: true,projects: []})
            self.users.replace([user]);
        },
        loadUser : flow(function*(email: string) {
            try {
                const firebaseUser: YoUser  = yield userFirebaseAPI.getUser(email);
                const user = User.create(MapToUserModel(firebaseUser));
                self.users.replace([user]);
            } catch (e: any) {
              throw new Error(`Failed to load user: ${e.message}`);
        }}),
        loadUsers : flow(function*() {
            try {
                const firebaseUsers : YoUser[] = yield userFirebaseAPI.getUsers();
                const users = firebaseUsers.map(user => User.create(MapToUserModel(user)))
                self.users.replace(users);
            } catch (e: any) {
              throw new Error(`Failed to load users: ${e.message}`);
            }}),
        saveUser : flow(function *() {
            const user = self.users[0];

            const rootStore = getRoot<typeof RootStore>(self);

            const dto: YoUser = MapToDto(user, rootStore.projectStore.projects, rootStore.toolStore.tools)
            try {
                yield userFirebaseAPI.saveUser(dto);
            } catch (e: any) {
              throw new Error(`Failed to save user: ${e.message}`);
            }
        }),
        deleteUser : flow(function *() {
            try {
                yield userFirebaseAPI.deleteUser(self.users[0].email);
            } catch (e: any) {
              throw new Error(`Failed to save user: ${e.message}`);
            }
        }),
    }));

const MapToUserModel = (user: YoUser) => {
  return {
    email: user.email,
    isAdmin: user.isAdmin,
    tools: user.tools?.map(userTool => {
      return {
        toolId: userTool.toolId,
        globalRoles: userTool.globalRoles || []
      }
    }) || [],
    projects: user.projects?.map(project => {
      return {
        projectId: project.projectId,
        tools: project.tools?.map(userProjectTool => {
          return {
            toolId: userProjectTool.toolId,
            toolProjectId: userProjectTool.toolProjectId,
            projectRoles: userProjectTool.projectRoles || [],
            gameEnvironments: userProjectTool.gameEnvironments?.map(ge => {
              return {
                toolGeId: ge.toolGeId,
                roles: ge.roles || []
              }
            }) || []
          }
        }) || []
      }
    }) || []
  }
};

const MapToDto = (user: IUser, projects : IProject[], tools: ITool[]): YoUser => {
  return {
    email: user.email,
    isAdmin: user.isAdmin,
    tools:
      user
        .tools
        // Filter out tools that don't exist
        .filter(userTool => tools.some(tool => tool.id === userTool.toolId))
        // Filter out tools that don't have any roles
        .filter(userTool => userTool.globalRoles.length > 0)
        .map((userTool): YoUserTool => {
          return {
            toolId: userTool.toolId,
            globalRoles: userTool.globalRoles
              // Filter out roles that don't exist  NB.  This could result in an empty list being saved
              .filter(role => tools.find(tool => tool.id === userTool.toolId)?.globalRoles.find(globalRole => globalRole.id === role))
              .map(s => s) || []
          }
        }) || [],
    projects:
      user
        .projects
        // Filter out projects that don't exist
        .filter(userProject => projects.some(project => project.id === userProject.projectId))
        // Filter out projects that don't have any tools
        .filter(userProject => userProject.tools.length > 0)
        .map((userProject): YoUserProjectToolsMap => {

          const project = projects.find(project => project.id === userProject.projectId);

          return {
            projectId: userProject.projectId,
            tools: userProject
              .tools
              // Filter out projects without any tools enabled
              .filter(userTool => project?.tools.find(projectTool => projectTool.toolId === userTool.toolId))
              .map((userTool): YoUserProjectTool => {
                return {
                  toolId: userTool.toolId,
                  toolProjectId: userTool.toolProjectId,
                  projectRoles: userTool.projectRoles
                    // Filter out project roles that don't exist
                    .filter(userProjectRole => tools.find(tool => tool.id === userTool.toolId)?.projectRoles.find(projectRole => projectRole.id === userProjectRole)),
                  gameEnvironments : userTool.gameEnvironments
                    // Filter out game environments that don't exist
                    .filter(userGe => project?.tools.find(projectTool => projectTool.toolId === userTool.toolId)?.gameEnvironments.find(ge => ge.toolGeId === userGe.toolGeId))
                    // Filter out game environments that don't have any roles
                    .filter(userGe => userGe.roles.length > 0)
                    .map((ge): YoUserProjectToolGe => {
                    return {
                      toolGeId: ge.toolGeId,
                      roles: ge.roles
                        // Filter out roles that don't exist  NB.  This could result in an empty list being saved
                        .filter(role => tools.find(tool => tool.id === userTool.toolId)?.gameEnvironmentRoles.find(geRole => geRole.id === role))
                        .map(s => s) || []
                    }
                  }) || []
                }
              }) || []
          }
        }) || []
  }
};

export interface IUserStore extends Instance<typeof UserStore> {}
