import React from "react";
import type {IRootStore} from "./root-store";
import {RootStore} from "./root-store";
import {ProjectStore} from "./project-store";
import {bearerTokenProvider} from "@yakoffice/yakoffice-firebase";
import {ToolStore} from "./tool-store";
import {UserStore} from './user-store';

export const rootStore = RootStore.create(
    {
        toolStore: ToolStore.create(),
        projectStore: ProjectStore.create(),
        userStore: UserStore.create()
    },
    {
            bearerTokenProvider : bearerTokenProvider
    });

const RootStoreContext = React.createContext<IRootStore>(rootStore);

export const RootStoreProvider = RootStoreContext.Provider;

export default RootStoreContext;
