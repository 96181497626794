import React, { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { observer } from 'mobx-react-lite';
import { IProjectToolMap } from '../../model/project-tool-map';
import { ITool } from '../../model/tool';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IProject } from '../../model/project';
import { IUser } from '../../model/user';
import { IRole } from '../../model/role';
import { IProjectToolGameEnvironment } from '../../model/project-tool-game-environment';
import { RoleDescriptionPopover } from '../role-description-popover/role-description-popover';
import { LabelHeader } from '@yakoffice/shared-components';

interface PropTypes{
  project: IProject,
  projectTool: IProjectToolMap,
  tool: ITool,
  user: IUser
}

export const GameEnvironmentRoles : FC<PropTypes> = observer(({project, projectTool, tool, user}) => {

  const handleToggleRoleStatus = (ge: IProjectToolGameEnvironment, role: IRole, enableRole: boolean ) => {
    user.setGameEnvironmentRoleStatus(project.id, tool.id, ge.toolGeId, role.id, enableRole)
  }

  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            <LabelHeader><i className={`fa fa-lock`} /> Game Environment Roles <RoleDescriptionPopover roles={tool.gameEnvironmentRoles}/></LabelHeader>
          </Form.Label>
        </Col>
      </Row>
      <Row data-testid={`tool-ge-roles-${tool.id}`}>
      {
        projectTool.gameEnvironments.map(ge => {
          return <Col md={"auto"} key={ge.name}>
            <div className="mb-md-2"><strong>{ge.name}</strong></div>
            <div className="mb-2 mb-md-0">
            {
              tool.gameEnvironmentRoles.map(role => {
                const userHasRole = user.hasGameEnvironmentRole(project.id, tool.id, ge.toolGeId, role.id);
                const uniqueId = `${tool.id}-${ge.toolGeId}-${role.id}`;
                return <Form.Check
                  key={uniqueId}
                  type="checkbox"
                  id={uniqueId}
                  data-testid={`ge-auth-checkbox-${uniqueId}`}
                  defaultChecked={userHasRole}
                  onChange={() => handleToggleRoleStatus(ge, role, !userHasRole)}
                  label={role.name} />
              })
            }
            </div>
          </Col>
        })
      }
      </Row>
    </>

  );
})
