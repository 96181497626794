import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {PortalRouteItems} from "../../routes/PortalRouteItems";
import React, {FC, useContext} from "react";
import {observer} from "mobx-react-lite";
import RootStoreContext from "../../stores/root-store-context";
import {AuthUserContext} from "@yakoffice/yakoffice-firebase";
import { IconCard } from "@yakoffice/icon-card";
import {sortByString} from '@yakoffice/yakoffice-firebase';
import { CenteredPanel, ProjectLogo, ProjectLogoPlaceholder } from '@yakoffice/shared-components';

export const Projects : FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const authUser = useContext(AuthUserContext);

    return (authUser &&
        <CenteredPanel id="projects">
            <Container>
                <Row className="justify-content-center">
                    {rootStore.projectStore.projects.length === 0
                        ? <p>No Projects Found</p>
                        : rootStore.projectStore.projects.slice().sort(sortByString("name")).filter(p => authUser.isAuthorisedForProject(p.id))
                            .map(project => {
                            return <Col xs="12" sm="12" md="4" key={project.id}>
                                        <IconCard
                                            title={project.name}
                                            icon={project.imageUrl
                                              ?<ProjectLogo src={require(`../../images/${project.imageUrl}`)} alt={project.name}/>
                                              :<ProjectLogoPlaceholder />}
                                            link={`${project.id}/${PortalRouteItems.Tools}`}
                                            data-testid="projectIconCard"
                                        />
                                    </Col>
                        })
                    }
                </Row>
            </Container>
        </CenteredPanel>
    )
})
