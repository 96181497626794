import type {Blocker, History, Transition} from 'history';
import {ContextType, useCallback, useContext, useEffect, useState} from 'react';
import {Navigator as BaseNavigator, UNSAFE_NavigationContext as NavigationContext} from 'react-router-dom';
import {flushSync} from "react-dom";

interface Navigator extends BaseNavigator {
  block: History['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & { navigator: Navigator };

/** READ-ME:
 * <Prompt> and usePrompt() were taken out of ReactRouter v6 with the intention they would be added later.
 * This is a workaround until we upgrade to a later version of v6 which contains them
 * See:
 *  https://github.com/remix-run/react-router/issues/8139
 *  https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
function useBlocker(blocker: Blocker, when = true) {
  const {navigator} = useContext(NavigationContext) as NavigationContextWithBlock;

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * <Prompt> and usePrompt() were taken out of ReactRouter v6 with the intention they would be added later.
 * This is a workaround until we upgrade to a later version of v6 which contains them
 * See:
 *  https://github.com/remix-run/react-router/issues/8139
 *  https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export function usePrompt(message: string, initialPageEdited = false) : [boolean, (setEdited: boolean) => void] {
  const [pageEdited, setPageEdited] = useState(initialPageEdited);
  const blocker = useCallback((tx: Transition) => {
    if (window.confirm(message)) {
      tx.retry();
    }
  }, [message]);
  useBlocker(blocker, pageEdited)

  // flushSync to remove batching which may prevent a rerender (i.e. setPageEdited changed) which would stop the blocker
  return [pageEdited, (edited: boolean) => flushSync(() => setPageEdited(edited))];
}
