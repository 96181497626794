import { Instance, types } from 'mobx-state-tree';

export const Role = types.model(
  "Role",
  {
    id: types.string,
    name: types.string,
    description: types.string
  }
)

export interface IRole extends Instance<typeof Role> {}
