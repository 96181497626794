import { Instance, types } from 'mobx-state-tree';

export const UserTool = types.model(
  "UserTool",
  {
    toolId: types.string,
    globalRoles: types.array(types.string)
  }
)

export interface IUserTool extends Instance<typeof UserTool> {}
