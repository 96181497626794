import { getFirestore, doc, getDoc, getDocs, collection, setDoc, deleteDoc, DocumentData, onSnapshot } from "firebase/firestore";
import { YoObserver } from '../types';
import { DocMapping } from './mappings/doc-mapping';


export const FirebaseAPI = () => {

  const getDocItem = async <T>(path: string, mapping: DocMapping<T>): Promise<T> => {
    const db      = getFirestore();
    const docRef  = doc(db, path);
    const _doc    = await getDoc(docRef);
    return mapping(_doc.id, _doc);
  }

  const subscribeToDocSnapshots = <T>(
    path      : string,
    mapping   : DocMapping<T>,
    observer  : YoObserver<T>): (() => void) => {

    const db  = getFirestore();

    return onSnapshot(doc(db, path), {
                        next: (snapshot) => {
                          observer.next && observer.next(mapping(snapshot.id, snapshot.data()));
                        },
                          error: observer.error
                      }
    )
  }

  const getCollectionItem = async <T>(path: string, id: string, mapping: DocMapping<T>): Promise<T> => {
    const db      = getFirestore();
    const docRef  = doc(db, path, id);
    const _doc    = await getDoc(docRef);
    return mapping(_doc.id, _doc.data())
  }

  const getCollectionItems = async <T>(path: string, mapping: DocMapping<T>): Promise<T[]> => {
    const db      = getFirestore();
    const docsRef = await getDocs(collection(db, path));
    return docsRef.docs.map((ds: DocumentData) => {
      return mapping(ds.id, ds.data());
    })
  }

  const subscribeToCollectionSnapshots = <T>(
    path      : string,
    mapping   : DocMapping<T>,
    observer  : YoObserver<T[]>): (() => void) => {

    const db  = getFirestore();

    return onSnapshot(collection(db, path), {
                        next: (snapshot) => {
                          observer.next &&
                          observer.next(
                            snapshot.docs.map((ds) => mapping(ds.id, ds.data())))
                        },
                        error: observer.error
                      }
    )
  }

  const saveCollectionItem = async (collectionPath: string, id: string, item: any) => {
    const db = getFirestore();
    return await setDoc(doc(db, collectionPath, id),  item);
  }

  const deleteCollectionItem = async (collectionPath: string, id: string) => {
    const db = getFirestore();
    return await deleteDoc(doc(db, collectionPath, id));
  }

  return {
    getDocItem,
    subscribeToDocSnapshots,
    getCollectionItem,
    getCollectionItems,
    subscribeToCollectionSnapshots,
    saveCollectionItem,
    deleteCollectionItem
  }

}



