import {useEffect, useState} from "react";
import {YoProject} from "../../types";
import { ProjectFirebaseApi } from '../../api';

// Subscribes to YoProject changes in Firestore and updates result accordingly
export const useYoProjectsSnapshots = () => {

  const [yoProjects, setYoProjects] = useState((): YoProject[] => []);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    return ProjectFirebaseApi()
      .subscribeToProjectsSnapshots({
        next: yoProjectsSnapshot => {
          setYoProjects(yoProjectsSnapshot);
        },
        error: e => {
          console.error(e);
          setError(e);
        }
      })
  }, [])

  return { yoProjects, error };
}
