import {Instance, types} from "mobx-state-tree";

export const ProjectToolGameEnvironment = types.model(
  "ProjectToolGameEnvironment",
  {
    name:"",
    toolGeId: ""
  });

export interface IProjectToolGameEnvironment extends Instance<typeof ProjectToolGameEnvironment> {}
