import 'firebase/auth';
import 'firebase/firestore';
import { YoObserver, YoTool } from '../types';
import { FirebaseAPI } from './firebase-api';
import { YakofficeFirestoreConfigPath } from '../utils';
import { DocMapping } from './mappings/doc-mapping';

const firebaseAPI = FirebaseAPI();

// Tools API which describes all the Tools available
export const ToolFirebaseApi = () => {

  const toolsPath = `${YakofficeFirestoreConfigPath}/tools`;

  const mapToTool : DocMapping<YoTool> = (id, data) => {
    return { ...{id: id},  ...data } as YoTool
  }

  const getToolsConfig = async (): Promise<YoTool[]> => {
    return await firebaseAPI.getCollectionItems(toolsPath, mapToTool);
  }

  // Subscribes an observer to YoTools snapshot updates from Firestore
  const subscribeToToolsSnapshots = (observer: YoObserver<YoTool[]>) : (() => void) => {
    return firebaseAPI.subscribeToCollectionSnapshots(toolsPath, mapToTool, observer);
  }

  return {
    getToolsConfig,
    subscribeToToolsSnapshots
  }

}
